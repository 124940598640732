<template>
	<div class="contant">
		<img src="@/assets/img/pc/logo.png" class="img" alt="">
		<div class="loginCon">
			<div class="gyxsCon">
				<img src="@/assets/img/m/gyxs.png" alt="">
			</div>
			<div class="formCon">
				<el-input v-model="phone" maxlength="11" placeholder="请输入手机号" @keyup.enter="login">
					<i slot="prefix" class="el-input__icon el-icon-mobile"></i>
				</el-input>
				<div class="btnCon" @click="login">
					<img class="icon" src="@/assets/img/pc/kt.png" alt="">
					<span class="txt">进入课堂</span>
				</div>
				<van-checkbox class="login_checkbox" v-model="isRemember" shape="square" icon-size="16px"
					checked-color="#B5802B">记住账号</van-checkbox>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				phone: "",
				isRemember: false
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let user = localStorage.getItem('user');
				if (!user) return;
				user = JSON.parse(user);
				if (!user.isRemember) return;
				this.isRemember = user.isRemember;
				this.phone = user.phone;
			},
			async login() {
				if (!this.phone) {
					this.$message.error("请输入手机号");
					return;
				}
				this.$toast.loading({
					message: '登录中...',
					forbidClick: true,
				});
				const res = await this.$api.jntsLogin({
					"phone": this.phone
				});
				const data = res.data;
				localStorage.setItem("webToken", data.token);

				localStorage.setItem("user", JSON.stringify({
					isRemember: this.isRemember,
					phone: data.phone,
					name: data.name,
					user_id: data.user_id
				}));

				setTimeout(() => {
					this.$toast.clear();
					this.$router.push('/m/home');
				}, 500)
			}
		}
	}
</script>

<style scoped lang="less">
	.contant {
		width: 100%;
		min-height: 100vh;
		background: url('../../assets/img/m/loginBg.png') no-repeat;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.img {
			width: 2.5rem;
			margin-bottom: .34rem;
			margin-top: 3.68rem;
			display: block;
		}

		.loginCon {
			width: 5rem;
			height: 4rem;
			background: url('../../assets/img/m/loginBg2.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			.gyxsCon{
				width: 3.3rem;
				margin: 0 auto;
				margin-top: .1rem;
				img {
					width: 100%;
				}
			}
			.formCon {
				width: 4rem;
				margin: 0 auto;
				margin-top: .2rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				/deep/.el-input__inner {
					border: 2px solid #B5802B;
					border-radius: .4rem;
				}
			
				/deep/.el-input__icon {
					font-size: .4rem;
					color: #B5802B;
				}
			
				.btnCon {
					width: 4rem;
					height: .57rem;
					background: #B5802B;
					border: 3px solid #B5802B;
					border-radius: .3rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: .12rem;
					margin-bottom: .2rem;
					cursor: pointer;
			
					.icon {
						width: .3rem;
					}
			
					.txt {
						font-size: .28rem;
						color: #fff;
						margin-left: .08rem;
					}
				}
			}
		}
	}
</style>